import React from 'react';



export default ({ children }) => <>
	<aside className="menu-sidebar  d-lg-block">
		<div className="logo">
			<div className="account-wrap">
				<div className="account-item clearfix js-item-menu">
					<div className="image">
						<img src="https://www.clipartmax.com/png/small/287-2878229_medium-image-female-avatar-png.png" alt="John Doe" />
						{/* <span className="alert-num">1</span> */}
					</div>
					<div className="content">
						<a className="js-acc-btn" href="#"> Welcome Root </a>
					</div>
				</div>
			</div>
		</div>
		<div className="menu-sidebar__content js-scrollbar1">
			<nav className="navbar-sidebar">
				<ul className="list-unstyled navbar__list">
					<li>
						<a href="/panel/accounts"> <i className="fas fa-table"></i>  حسابات الطلاب  </a>
					</li>
					<li>
						<a href="/panel/uploads">
							<i className="fas fa-table"></i> تعبيرات الطلاب </a>
					</li>

				</ul>
			</nav>
		</div>
	</aside>

	<div className="page-container">
		<header className="header-desktop">
			<div className="section__content section__content--p30">
				<div className="container-fluid">
					<div className="header-wrap">
						برنامج التحصيل القرائي
            <div className="header-button">

						</div>
					</div>
				</div>
			</div>
		</header>
		{children}
		<div className="row ">
			<div className="col-md-12">
				<div className="copyright">
				</div>
			</div>
		</div>
	</div>

</>