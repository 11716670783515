import React, { useState, useEffect } from "react";

import axios from 'axios';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';

function hashCode(str) { // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}


export default (props) => {
  const [tests, setTests] = useState([]);
  const [breadcrump, setBreadCrump] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let id = props.match.params.skill;

  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/skill/${id}`)
      .then(res => {
        setTests(res.data.payload)
        setBreadCrump(res.data.meta.breadcrump)
      });
  }, [props.match.params.skill]);

  return (<>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <h2 className="title-1">
                        <Link to={`/skills/`} > المهارات </Link>

                      </h2>
                    </li>
                    {breadcrump.map((crump) => <li class="breadcrumb-item"><Link key={crump.id} to={`/skill/${crump.id}`}  > <span style={{ color: intToRGB(hashCode(crump.title)) }}> {crump.title} </span> </Link> </li>)}

                  </ol>
                </nav>
              </div>
            </div>
          </div>




          <div className="row centered-list">
          {
          
            tests && tests.map((data) => <Link key={data.id} to={`/${'type' in data ? data.type : 'skill'}/${data.id}`} className={`au-card-grid col-md-3 ${!data.logo ? 'no-logo' : ''}`} >  { data.logo && <img src={`https://api.alaamz.xyz/alpha/${data.logo}`} /> }  <span style={{ color: intToRGB(hashCode(data.title)) }}>{data.title}  </span> </Link>)
          }
          </div>
        </div>
      </div>
    </div>
  </>);
}