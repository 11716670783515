import React, { useMemo, useState, useCallback, useEffect } from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { isAuthed } from '../includes/auth';

const display = (data) => {
  if (data.match(/\.(jpe?g|gif|png)/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <img src={data} />
  }
  if (data.match(/\.mp4/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <video width="480" height="320" controls="controls">
      <source src={data} type="video/mp4" />
    </video>
  }

  if (data.match(/\.pdf/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <object
      data={data}
      type="application/pdf"
      width="500"
      height="678"
      style={{ width: "100%" }}
    >
      <iframe
        src={data}
        width="500"
        height="678"
        style={{ width: "100%" }}
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </object>
  }

  if (data.match(/\.youtube/)) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = data.match(regExp);

    const id = (match && match[2].length === 11)
      ? match[2]
      : null;

    data = '//www.youtube.com/embed/' + id;

    return <iframe width="500" height="678" style={{ width: "100%" }} src={data} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  }

  if (data.match(/wordwall/)) {
    return <iframe width="500" height="678" style={{ width: "100%" }} src={`https://api.alaamz.xyz/wordwall?url=${data}`} frameborder="0" allowfullscreen></iframe>
  }


  return <span> {data} </span>


}
const Content = (props) => {

  let content = props.payload;
  if (!(content instanceof Array)) {
    content = [content];
  }

  return <>
    {content.map((data) => display(data))}
  </>
}



export default (props) => {
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [record, setRecord] = useState(null);
  const [play, setPlay] = useState(false);

  const onStop = (e) => setRecord(e);

  const handleUpload = useCallback(() => {
    let data = new FormData();
    data.append('content', new File([record.blob], 'record.wav'));
    data.append('auth', localStorage.getItem("auth"));
    data.append("skill", props.match.params.skill);
    axios.post("https://api.alaamz.xyz/upload", data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }
    }).then(res => alert("تم بنجاح"))
  },
    [record],
  )

  const [tests, setTests] = useState(null);
  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/files/${props.match.params.skill}`)
      .then(res => setTests(res.data.payload));
  }, []);


  return <>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1">سجل صوتك</h2>
                <Link to={`/skill/${props.match.params.skill.split('-')[0]}`} > <FontAwesomeIcon icon={faArrowLeft} /> </Link>

              </div>
            </div>
          </div>
          {tests && <div className="row au-card content">
            <Content payload={tests} />
           
              <div className="row" style={{ margin: '0px', width: '100%', justifyContent: 'center' }} >
                <AudioReactRecorder canvasHeight={50} backgroundColor={'#ffffff'} foregroundColor={'#4272d7'} state={recordState} onStop={onStop} />
              </div>
              <div className="row" style={{ margin: '0px', width: '100%', justifyContent: 'space-around' }}>
                {
                  recordState !== RecordState.START && <button className='au-btn au-btn-icon au-btn--blue' onClick={() => setRecordState(RecordState.START)}>إبدأ التسجيل</button>
                }
                {
                  recordState === RecordState.START && <button className='au-btn au-btn-icon au-btn--blue' onClick={() => setRecordState(RecordState.STOP)}>إيقاف التسجيل</button>
                }
                {
                  recordState === RecordState.STOP && <>
                    {play && <button className='au-btn au-btn-icon au-btn--blue' onClick={() => setPlay(false)}>إيقاف الإستماع</button>}
                    {!play && <button className='au-btn au-btn-icon au-btn--blue' onClick={() => setPlay(true)}>الإستماع للتسجيل</button>}
                    {/* <button className='au-btn au-btn-icon au-btn--blue' onClick={() => handleUpload()}>رفع التسجيل</button> */}
                  </>
                }
                {
                  play && <audio id="audio" style={{ display: 'none' }} controls src={record && record.url} autoPlay ></audio>
                }
              </div>
            
           

          </div>}
        </div>
      </div>
    </div>
  </>
}