import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom'
import Router from './routes/router';
import ScrollToTop from './containers/ScrollToTop';
import './resources/style/bootstrap.min.css';
import './resources/style/bootstrap-rtl.css';
import './resources/style/style.css';
import './resources/style/style-rtl.css';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Router >
        <ScrollToTop />
      </Router>
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector('.page-wrapper')
);
