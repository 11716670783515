import React, { useState, useEffect } from "react";

import axios from 'axios';

export default () => {
  const [tests, setTests] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/tests`)
      .then(res => setTests(res.data.payload));
  }, []);
  return (<>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1">الإختبارات التشخيصية</h2>
              </div>
            </div>
          </div>
          <div className="row centered-list">
            {
              tests && tests.map((data) => <a download href={`https://api.alaamz.xyz/uploads/${data.url}`} className="au-card-grid col-md-3">{data.title}</a>)
            }
          </div>
        </div>
      </div>
    </div>
  </>);
}