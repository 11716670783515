import axios from 'axios';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { isAuthed } from '../includes/auth';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const link = {
  color: '#4272d7'
}

const display = (data) => {
  if (data.match(/\.(jpe?g|gif|png)/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <img src={data} />
  }
  if (data.match(/\.mp4/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <video width="480" height="320" controls="controls">
      <source src={data} type="video/mp4" />
    </video>
  }

  if (data.match(/\.pdf/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <object
      data={data}
      type="application/pdf"
      width="500"
      height="678"
      style={{ width: "100%" }}
    >
      <iframe
        src={data}
        width="500"
        height="678"
        style={{ width: "100%" }}
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </object>
  }

  if (data.match(/\.youtube/)) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = data.match(regExp);

    const id = (match && match[2].length === 11)
      ? match[2]
      : null;

    data = '//www.youtube.com/embed/' + id;

    return <iframe width="500" height="678" style={{ width: "100%" }} src={data} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  }

  if (data.match(/wordwall/)) {
    return <iframe width="500" height="678" style={{ width: "100%" }} src={`https://api.alaamz.xyz/wordwall?url=${data}`} frameborder="0" allowfullscreen></iframe>
  }


  return <span> {data} </span>


}
const Content = (props) => {

  let content = props.payload;
  if (!(content instanceof Array)) {
    content = [content];
  }

  return <>
    {content.map((data) => display(data))}
  </>
}


function StyledDropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: "application/pdf,image/*", multiple: false, onDrop: (e) => props.onDrop(e) });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={link}>أختارُ الملف</p> أو قم بسحب وإفلات الملف هنا
      </div>
    </div>
  );
}

function UploadProgress(props) {

  return <div className="container">
    <div style={baseStyle}>
      <p>{props.progress == 100 ? 'تم رفع الملف بنجاح' : props.progress}</p>
    </div>
  </div>
};

export default (props) => {
  const [percentage, setPercentage] = useState(0);
  const handleUpload = useCallback((file) => {
    let data = new FormData();
    data.append('content', file[0]);
    data.append('auth' , localStorage.getItem("auth"));
    data.append("skill" , props.match.params.skill);
    axios.post("https://api.alaamz.xyz/upload", data, {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setPercentage(percentCompleted);
      }
    }).then(res => alert("تم بنجاح") );
  },
    [props],
  )

  const [tests, setTests] = useState(null);
  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/files/${props.match.params.skill}`)
      .then(res => setTests(res.data.payload));
  }, []);
  return <>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1">إصنع</h2>
                <Link to={`/skill/${props.match.params.skill.split('-')[0]}`} > <FontAwesomeIcon icon={faArrowLeft} /> </Link>

              </div>
            </div>
          </div>
          {tests && <div className="row au-card content">
            <Content payload={tests} />


          </div>
          }
        </div>
      </div>
    </div>
  </>
}