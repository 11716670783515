import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';

const display = (data) => {
  if (data.match(/\.(jpe?g|gif|png)/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <img src={data} />
  }
  if (data.match(/\.mp4/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <video width="480" height="320" controls="controls">
      <source src={data} type="video/mp4" />
    </video>
  }

  if (data.match(/\.pdf/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <object
      data={data}
      type="application/pdf"
      width="500"
      height="678"
      style={{ width: "100%" }}
    >
      <iframe
        src={data}
        width="500"
        height="678"
        style={{ width: "100%" }}
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </object>
  }

  if (data.match(/\.youtube/)) {
    data = data.trim();

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = data.match(regExp);

    const id = (match && match[2].length === 11)
      ? match[2]
      : null;

    data = '//www.youtube.com/embed/' + id;

    return <iframe width="500" height="678" style={{ width: "100%" }} src={data} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  }

  if (data.match(/wordwall/)) {
    return <iframe width="500" height="678" style={{ width: "100%" }} src={`https://api.alaamz.xyz/wordwall?url=${data}`} frameborder="0" allowfullscreen></iframe>
  }


  return <span> {data} </span>


}



export default class index extends Component {
  state = {
    uploads: [],
  }

  getUploads() {
    axios.get(`https://api.alaamz.xyz/contents`)
      .then(res => this.setState({ uploads: res.data.payload }));
  }
  constructor(props) {
    super(props);
    this.getUploads();
    console.log(this.props.match.params);
  }
  render() {
    return <div className="main-content">
      <div className="section__content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1"> تفاعلات الطلاب </h2>

              </div>
            </div>
          </div>
          <div className="row">
            {!("id" in this.props.match.params) && <div className="col-md-12 ">
              <div className="au-card au-card--list">
                <table className="table table-hover">
                  <tbody>
                    {this.state.uploads && this.state.uploads.map((upload) => <tr class="data-2">
                      <td>
                        <span>
                          <Link to={`uploads/${upload.id}`}>  {upload.task} </Link>
                        </span>
                      </td>
                      <td>
                        <span>
                          {upload.fullname}
                        </span>
                      </td>
                      <td>  </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>}


          </div>
        </div>
      </div>

    </div>
  }
}
