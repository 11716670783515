import React, { Component, Fragment } from 'react'
import axios from 'axios' ;

export default class index extends Component {
  state = {
    users: [] ,
    isAddDialogOpen: false ,
    form: {
      username: '' ,
      password: '' ,
      fullname: ''
    } ,
    formErrors : {
      username: null ,
      password: null ,
      fullname: null
    } 
  }
  addNewStudent() {
    axios.post(`https://api.alaamz.xyz/users` , this.state.form)
      .then(res => {
        this.getUsers() ; 
        this.setState({isAddDialogOpen: false})
        alert(res.data.message) ;
      });
  }

  getUsers() {
    axios.get(`https://api.alaamz.xyz/users` , this.state.form)
      .then(res => this.setState({users: res.data.payload }) );
  }
  constructor(props) {
    super(props) ;
    this.getUsers() ;
  }
  render() {
    return <div className="main-content">
      <div className="section__content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1"> الحسابات </h2>
                <a href="javascript:;" onClick={() => this.setState({isAddDialogOpen: true })} className="au-btn au-btn-icon au-btn--blue"  >
                  <i className="fas fa-plus"></i> إضافة حساب
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <div className="au-card au-card--list">
                <table className="table table-hover">
                  <tbody>
                  {this.state.users && this.state.users.map((user) =>  <tr class="data-2">
                      <td>
                       <span>
                         {user.fullname}
                                    </span>
                      </td>
                      <td>
                        <span>
                         {user.username}
                                    </span>
                      </td>
                      <td>  </td>
                      <td class="table-actions">
                        <a href="" class="btn btn-link">
                          <i class="fa fa-edit"></i>
                        </a>
                        <a href="javascript:;" data-toggle="modal" data-target="#delete" data-id="{{$album->id}}" data-title="{{$album->translations['title']}}" class="btn btn-link">
                          <i class="fa fa-trash"></i>
                        </a>
                        <a href="" class="btn btn-link">
                          <i class="fa fa-images"></i>
                        </a>
                      </td>
                    </tr> )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.state.isAddDialogOpen &&     <div className="modal fade show" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">  إضافة مستخدم جديد  </h4>
            </div>
            <div className="modal-body">
              <form>
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label">الإسم الحقيقي</label>
                  <input type="text" class="form-control" onChange={(e) => this.setState({
                    form: {
                      ...this.state.form ,
                      fullname: e.target.value 
                    } ,
                  })} />
                </div>
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label"> إسم المستخدم </label>
                  <input type="text" class="form-control" onChange={(e) => this.setState({
                    form: {
                      ...this.state.form ,
                      username: e.target.value 
                    } ,
                  })} />
                 </div>
                 <div class="form-group">
                  <label for="recipient-name" class="col-form-label"> كلمة السر </label>
                  <input type="text" class="form-control" onChange={(e) => this.setState({
                    form: {
                      ...this.state.form ,
                      password: e.target.value 
                    } ,
                  })} />
                </div>
      
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => this.setState({isAddDialogOpen: false })}>إغلاق</button>
              <button type="button" className="au-btn au-btn--blue" onClick={() => this.addNewStudent() }>إضافة</button>
            </div>
          </div>
        </div>
      </div>}
    </div> 
  }
}
