import React, { useState, useEffect, useMemo } from "react";

import axios from 'axios';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone'
import { isAuthed } from "../includes/auth";


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const link = {
  color: '#4272d7'
}
const display = (data) => {
  if (data.match(/\.(jpe?g|gif|png)/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <img src={data} />
  }
  if (data.match(/\.mp4/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <video width="480" height="600" style={{width: '100%' , marginTop: '10px'}} controls="controls">
      <source src={data} type="video/mp4" />
    </video>
  }

  if (data.match(/\.ogg/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <audio width="480" controls="controls" style={{width: '100%' , marginTop: '10px'}}>
      <source src={data} type="audio/ogg" />
    </audio>
  }

  if (data.match(/\.pdf/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <object
      data={data}
      type="application/pdf"
      width="500"
      height="678"
      style={{ width: "100%" }}
    >
      <iframe
        src={data}
        width="500"
        height="678"
        style={{ width: "100%" }}
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </object>
  }

  if (data.match(/\.youtube/)) {
    data = data.trim();

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = data.match(regExp);

    const id = (match && match[2].length === 11)
      ? match[2]
      : null;

    data = '//www.youtube.com/embed/' + id;

    return <iframe width="500" height="678" style={{ width: "100%" }} src={data} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  }

  if (data.match(/wordwall/)) {
    return <iframe width="500" height="678" style={{ width: "100%" }} src={`https://api.alaamz.xyz/wordwall?url=${data}`} frameborder="0" allowfullscreen></iframe>
  }


  if (data.match(/liveworksheets/)) {
    return <iframe width="500" height="1500" style={{ width: "100%" }} src={`https://api.alaamz.xyz${data}`} frameborder="0" allowfullscreen></iframe>
  }


  return <span> {data} </span>


}
const Content = (props) => {

  let content = props.payload.content;
  if (!(content instanceof Array)) {
    content = [content];
  }

  return <>
    {content.map((data) => display(data))}
  </>
}




function StyledDropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: props.accept, multiple: false, onDrop: (e) => props.onDrop(e) });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={link}>أختارُ الملف</p> أو قم بسحب وإفلات الملف هنا
      </div>
    </div>
  );
}

function UploadProgress(props) {

  return <div className="container">
    <div style={baseStyle}>
      <p>{props.progress == 100 ? 'تم رفع الملف بنجاح' : props.progress}</p>
    </div>
  </div>
};

export default (props) => {
  const [content, setContent] = useState(null);
  const [uploadText, setUploadText] = useState("");

  let id = props.match.params.skill;

  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/content/${id}`)
      .then(res => {
        setContent(res.data.payload)
        console.log(res.data.payload);
      });
  }, []);

  const getBackLink = (id) => {
    let splits = content.id.split('-') ;
    splits.pop() ;
    return splits.join("-");
  }

  return <>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {content && <div className="overview-wrap">
                <h2 className="title-1"> {content.title} </h2>
                <Link to={`/skill/${getBackLink(content.id)}`} > <FontAwesomeIcon icon={faArrowLeft} /> </Link>
              </div>}

            </div>
          </div>
          <div className="row au-card content">
            {content && <Content payload={content} />}
            {
               false && content && content.type == 'search' && <>
                <StyledDropzone onDrop={() => console.log()} />
                <span> او قم بكتابة البحث هنا </span>
                <div className="container">
                  <textarea style={{ width: '100%', height: '150px', border: '1px solid gray', borderRadius: '3px' }} />
                </div>
                <input type="submit" class="btn btn-primary  btn-flat" name="submit" value="إرسال" />
              </>

            }

          
          </div>


        </div>
      </div>
    </div>
  </>
}