import React from 'react';

export default ({ children }) => <>
  <div className="main-content">
    <div className="section__content section__content--p30">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="overview-wrap">
              <h2 className="title-1">الهدف العام من البرنامج</h2>
            </div>
          </div>
        </div>
        <div className="row au-card">
          <p className="has-line-data" data-line-start="4" data-line-end="6"> يهدف البرنامج إلى تنمية التحصيل القرائي لذوي صعوبات تعلم القراءة، وذلك استنادًا على مبادئ التصميم الشامل للتعلم </p>
          <p className="has-line-data" data-line-start="4" data-line-end="6"> استند في إعداد هذا البرنامج على الأدبيات السابقة التي وظفت التصميم الشامل للتعلم في التدريس بشكل عام، والتدريس الإلكتروني بشكل خاص . </p>
          <p className="has-line-data" data-line-start="4" data-line-end="6"> وبناءً على ذلك تم إعداد هذا البرنامج المتضمن لأساليب العرض المتنوعة للمهارات الأساسية لمادة القراءة، وطرق الاندماج المختلفة التي يتفاعل معها التلميذ أثناء التعلم، بالإضافة لأدوات التعبير التي يستخدمها التلميذ في نهاية تلقيه للمهارة .  </p>
          <p className="has-line-data" data-line-start="4" data-line-end="6">
            بدايةً يتم اختبار التلميذ اختبارًا تشخيصيًا يقيس نقاط الاحتياج لديه في مادة القراءة، ثم ينتقل بعدها إلى البرنامج الإلكتروني لتعلم تلك المهارات التي تحتاجها، مع متابعة مستمرة من قبل المعلم
            لاستجابات التلاميذ من خلال مايتم رفعه من ملفات صوتية و كتابية ونحوها . </p>
          <p className="has-line-data"> إعداد المعلمة : <b>آلاء بنت معيض الرزقي</b>   </p>
          <p className="has-line-data" data-line-start="4" data-line-end="6"> نسعد بملاحظاتكم ومقترحاتكم على البريد الإلكتروني التالي  : <a href="mailto:Alaa.alrezqi@gmail.com">Alaa.alrezqi@gmail.com</a></p>
        </div>
      </div>
    </div>
  </div>
</>