import React, { useState, useEffect } from 'react'
import axios from 'axios';
const Display = ({ data }) => {
  if (data.match(/\.(jpe?g|gif|png)/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <img src={data} />
  }
  if (data.match(/\.mp4/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <video width="480" height="320" controls="controls">
      <source src={data} type="video/mp4" />
    </video>
  }
  if (data.match(/\.wav/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <audio width="480" height="320" controls="controls">
      <source src={data} type="video/mp4" />
    </audio>
  }


  if (data.match(/\.pdf/)) {
    data = 'https://api.alaamz.xyz/uploads/' + data;
    return <object
      data={data}
      type="application/pdf"
      width="500"
      height="678"
      style={{ width: "100%" }}
    >
      <iframe
        src={data}
        width="500"
        height="678"
        style={{ width: "100%" }}
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </object>
  }


  return <span> {data} </span>
}

export default (props) => {
  const [content, setContent] = useState(null);
  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/contents/${props.match.params.skill}`)
      .then(res => {
        setContent(res.data.payload)
        console.log(res.data.payload);
      });
  }, [props.match.params.id]);



  return <> { content && <div className="main-content">
    <div className="section__content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="overview-wrap">
              <h2 className="title-1"> {content.task} بواسطة {content.fullname} </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="au-card au-card--list">
              <Display data={content.content} />
            </div>
          </div>
        </div>
      </div>
    </div >
  </div>
  } </>

}
