import React, { useState, useEffect } from "react";

import axios from 'axios';
import { Link } from 'react-router-dom';
var stringToColour = function(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export default () => {
  const [tests, setTests] = useState([]);
  useEffect(() => {
    axios.get(`https://api.alaamz.xyz/skills`)
      .then(res => setTests(res.data.payload));
  }, []);
  return <>
    <div className="main-content">
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="overview-wrap">
                <h2 className="title-1">المهارات</h2>
                
              </div>
            </div>
          </div>
          <div className="row centered-list">
            {
              tests && tests.map((data) => <Link to={`/skill/${data.id}`} className="au-card-grid col-md-3 no-logo"><span style={{color: stringToColour(data.title)}}>  {data.title} </span></Link>)
            }
          </div>
        </div>
      </div>
    </div>
  </>;
}