import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios';

export default (props) => {
	const [auth, setAuth] = useState({ username: '', password: '' });
	const handleLogin = useCallback((data) => {
		axios.post("https://api.alaamz.xyz/auth", data).then(res => {
			if(res.data.success == true) {
				localStorage.setItem("auth" , res.data.payload.auth);
				if(data.username == "root") {
					window.location.href = "/panel" ; 
				} else {
					window.location.href = "/" ;
				}
			} 
		});
	}, [props])
	return <div class="col-md-4" style={{ margin: '50px auto' }}>
		<h3 class="title" > مرحباُ  </h3>
		<section class="au-card">
			<div class="form-group has-feedback">
				<label>إسم المستخدم</label>
				<input type="text" name="username" onChange={(e) => setAuth({ ...auth, 'username': e.target.value })} class="form-control" placeholder="" />
			</div>
			<div class="form-group has-feedback">
				<label>كلمة السر</label>
				<input type="password" name="password" onChange={(e) => setAuth({ ...auth, 'password': e.target.value })} class="form-control" placeholder="" />
			</div>
			<div class="form-group">
				<input onClick={() => handleLogin(auth)} type="submit" class="btn btn-primary btn-block btn-flat" name="submit" value="دخول" />
			</div>
		</section>
	</div>
}
