import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard , faScroll , faAsterisk , faHeart , faBook} from '@fortawesome/free-solid-svg-icons';



export default ({ children }) => <>
	<aside className="menu-sidebar  d-lg-block">
		<div className="logo">
			<div className="account-wrap">
				<div className="account-item clearfix js-item-menu">
					<div className="image">
						<img src="https://www.clipartmax.com/png/small/287-2878229_medium-image-female-avatar-png.png" alt="John Doe" />
						{/* <span className="alert-num">1</span> */}
					</div>
					<div className="content">
						<a className="js-acc-btn" href="#">مرحبًا بالضيف</a>
					</div>
				</div>
			</div>
		</div>
		<div className="menu-sidebar__content js-scrollbar1">
			<nav className="navbar-sidebar">
				<ul className="list-unstyled navbar__list">
					
					<li>
						<a href="/about">
							<FontAwesomeIcon icon={faAddressCard}  size="xs" color="#75c155"/> التعريف بالبرنامج
							</a>
					</li>
					<li>
						<a href="/guid">
							<FontAwesomeIcon icon={faBook}  size="xs" color="#75c155"/> دليل الاستخدام
							</a>
					</li>
					<li>
						<a href="/tests">
						 <FontAwesomeIcon icon={faScroll}  size="xs" color="#75c155" /> الاختبارات التشخيصية</a>
					</li>
					<li>
						<a href="/skills">
							<FontAwesomeIcon icon={faAsterisk}  size="xs" color="#75c155"/>المهارات</a>
					</li>
					<li>
						<a href="/thanks">
							<FontAwesomeIcon icon={faHeart}  size="xs" color="#75c155"/>شكر وتقدير</a>
					</li>

				</ul>
			</nav>
		</div>
	</aside>

	<div className="page-container">
		<header className="header-desktop">
			<div className="section__content section__content--p30">
				<div className="container-fluid">
					<div className="header-wrap">
						برنامج التحصيل القرائي
            <div className="header-button">

						</div>
					</div>
				</div>
			</div>
		</header>
		{children}
		<div className="row ">
			<div className="col-md-12">
				<div className="copyright">
				</div>
			</div>
		</div>
	</div>

</>