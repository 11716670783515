import React from 'react';

import { Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router'

import Panel from '../containers/Panel';
import AdminPanel from '../containers/AdminPanel';

import Tests from './tests';
import About from './about';
import Skills from './skills';
import Skill from './skill';
import Content from './content';
import Upload from './upload';
import Record from './record';

import AccountsList from './accounts'
import Uploads from './uploads'
import Display from './uploads/display'

import Auth from './auth'

import { isAuthed } from '../includes/auth';


export default () =>
	<Switch>
		<Route path="/auth" component={Auth} />
		<Route path="/panel" render={({ match: { path }, location: { pathname } }) => {
			if (!isAuthed()) {
				window.location.href = "/auth"
			}
			if (isAuthed()) {
				return <AdminPanel>
					<Route path={`${path}/accounts`} component={AccountsList} />
					<Route exact path={`${path}/uploads`} component={Uploads} />
					<Route path={`${path}/uploads/:skill`} component={Display} />
				</AdminPanel>
			}
		}} />
		<Panel>
			<Switch>
				<Route path="/about" component={About} />
				<Route path="/skills" component={Skills} />
				<Route path="/skill/:skill" component={Skill} />
				<Route path="/content/:skill" component={Content} />
				<Route path="/upload/:skill" component={Upload} />
				<Route path="/record/:skill" component={Record} />
				<Route path="/tests" component={Tests} />
				<Route path="/thanks" component={() => <img src='https://api.alaamz.xyz/thanks.jpg' />} />
				<Route path="/guid" component={() => window.location.href = 'https://api.alaamz.xyz/guid.pdf'} />
				<Route path="/" component={About} />
			</Switch>
		</Panel>
	</Switch>

